import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import PortfolioHeadline from '../../../PortfolioHeadline';
import PortfolioActions from '../../components/Actions';
import PortfolioCardContent from './CardContent';
import ApiConnectionFailureBlock from '../components/ApiConnectionFailureBlock';
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.m,
}));
const HeadlineContainer = styled(ContainerRow)((props) => ({
    height: 'auto',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: props.theme.spacing_sizes.m,
}));
const PortfolioCardViewCard = ({ portfolio = undefined, displayState, primaryActionType, isSelected, isLoading = undefined, disabled = false, onAction = () => { }, moreActionBtnOptions = undefined, showActionsBtn = true, }) => (_jsxs(Container, { children: [_jsxs(HeadlineContainer, { children: [_jsx(PortfolioHeadline, { portfolio: portfolio, isLoading: isLoading }), showActionsBtn && portfolio && (_jsx(PortfolioActions, { portfolio: portfolio, displayState: displayState, primaryActionType: primaryActionType, moreActionBtnOptions: moreActionBtnOptions, disabled: disabled, onAction: onAction }))] }), displayState === 'default' && (_jsx(PortfolioCardContent, { portfolio: portfolio, isLoading: isLoading })), displayState === 'error' && portfolio && (_jsx(ApiConnectionFailureBlock, { portfolio: portfolio, isSelected: isSelected, onAction: onAction }))] }));
export default PortfolioCardViewCard;
